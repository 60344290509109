import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const fixIOSZoomMediaQuery = `@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 767px)`;
export const fixIOSZoomContent = css`
  &:focus {
    font-size: 16px !important;
    &::placeholder {
      font-size: 15px;
    }
  }
`;
export const fixIOSZoom = css`
  ${fixIOSZoomMediaQuery} {
    ${fixIOSZoomContent}
  }
`;

export const textFieldStyle = ({
  theme,
  prefix = '',
  clearable = false,
  icon = false,
  iconColor = 'bahamaBlue',
  inputSelector = 'input',
  containerSelector = '&',
  alwaysShowClear = false,
}) => css`
  display: block;
  position: relative;

  width: 100%;
  ${containerSelector} {
    isolation: isolate;
    overflow: hidden;
  }
  & > .suffix-container {
    position: absolute;
    pointer-events: none;
    display: flex;
    inset: 0;
    align-items: center;
    ${theme.typography.control};

    & > .before-suffix {
      visibility: hidden;
      padding: 0 0 0 14px;
    }
  }
  ${inputSelector} {
    ${fixIOSZoom};
    ${theme.typography.control};
    height: 48px;
    width: 100%;
    border-radius: 0;
    padding: 0 14px;

    border: solid thin ${theme.colors.quartz};
    border-radius: 4px;
    background-color: ${theme.colors.white};

    transition: border-color 150ms linear;

    &:focus-visible {
      outline: none;
      border-color: ${theme.colors.bahamaBlue};
    }
    &:disabled {
      background-color: ${theme.colors.whiteSmoke};
      color: ${theme.colors.dimGray};
    }
    &:read-only {
      border: solid thin ${theme.colors.quartz};
      background-color: ${theme.colors.aliceBlue};
    }
    &::placeholder {
      color: ${theme.colors.dimGray};
    }
  }
  &.has-error ${inputSelector} {
    border-color: ${theme.colors.error};
    &:focus-visible {
      border-color: ${theme.colors.error};
    }
  }
  ${(icon || clearable) &&
  css`
    ${inputSelector} {
      padding-right: 40px;
    }
    span.icon,
    span.clear {
      display: flex;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      width: 30px;
      transition: opacity 150ms linear, color 150ms linear;
      align-items: center;
      cursor: pointer;
      ${theme.media.hover} {
        &:hover {
          color: ${theme.colors.pacificBlue};
        }
      }
    }
    span.icon {
      color: ${theme.colors[iconColor]};
    }
  `}
  ${clearable &&
  !alwaysShowClear &&
  css`
    span.clear {
      opacity: 0;
      color: ${theme.colors.trout};
    }
    &.has-value {
      span.clear {
        opacity: 1;
      }
    }
  `}
  ${textFieldPrefixStyle({ prefix, inputSelector, theme })}
`;

export const textFieldPrefixStyle = ({
  prefix,
  inputSelector = 'input',
  theme,
}) => css`
  ${!!prefix &&
  css`
    ${inputSelector} {
      padding-left: 51px;
    }
    &:before {
      ${theme.typography.control};
      content: '${prefix}';
      position: absolute;
      left: 1px;
      top: 1px;
      bottom: 1px;
      width: 37px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: solid thin ${theme.colors.white};
      background-color: ${theme.colors.aliceBlue};
      z-index: 1;
      padding-right: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: ${({ theme }) => theme.weights.medium};
    }
  `}
`;

export default styled.div`
  ${textFieldStyle};
`;
